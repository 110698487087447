import React, { storage } from 'react';
import { withRouter, Link } from 'react-router-dom';
import '../assets/styles/main.css';
import 'firebase/database';
import app from 'firebase/app';
import { Formik } from "formik";
import * as Yup from "yup";
import { FirebaseContext } from '../firebase';

const today = new Date();
const current_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate());
// getMonth() values are 0-11, where Jan is 0, and Dec is 11, so add +1 to get result
// getDate() gets the day of the month (1-31)

const createActivitySchema = Yup.object().shape({
    name: Yup
        .string()
        .min(10, "Title: minimum 10 characters")
        .required("Title required"),
    date: Yup
        .date()
        .min(current_date, "Date must be later than today")
        .required("Date of Activity required"),
    startTime: Yup
        .string()
        .required("Start Time required"),
    endTime: Yup
        .string()
        .required("End Time required")
        .test("check_endTime", "End Time must be after Start Time",
            function(values){
                return this.parent.startTime < values;
            }),
    location: Yup
        .string()
        .min(10, "Location: minimum 10 characters")
        .required("Location required"),
    hostOrganization: Yup
        .string()
        .min(3, "Organization: minimum 3 characters")
        .required("Host Organization required"),
    suitableParticipants: Yup
        .string()
        .required("Suitable Participants required"),
    socialImpactArea: Yup
        .string()
        .required("Social Impact Area required"),
    shortDesc: Yup
        .string()
        // .min(100, "Short Description: minimum 100 characters")
        .required("Short Description required"),
    description: Yup
        .string()
        // .min(250, "Full Description: minimum 250 characters")
        .required("Full Description required"),
    externalLink: Yup
        .string()
        .url("URL format: http://www.example.com")        
});

const CreateActivityView = ({ onSubmit }) => {
    
    // const storage = app.storage();
    // const [image, setImage] = useState(null);

    // const handleChanges = e => {
    //     if (e.target.files[0]) {
    //         setImage(e.target.files[0])
    //     }
    // };

    // const handleUpload = () => {

    //     const uploadTask = storage.ref(`images/${image.name}`).put(image);
        
    //     uploadTask.on(
    //         "state_changed",
    //         snapshot => {},
    //         error => {
    //             console.log(error);
    //         },
    //         () => {
    //           storage
    //             .ref("images")
    //             .child(image.name)
    //             .getDownloadURL()
    //             .then(url => {
    //                 console.log(url);
    //             });
    //         }
    //     );
    // };

    // console.log("image: ", image); // to see if image uploads on the web
    
    return (
        <Formik
            initialValues={{
                name: "",
                date: "",
                startTime: "",
                endTime: "",
                location: "",
                hostOrganization: "",
                suitableParticipants: "",
                socialImpactArea: "",
                shortDesc: "",
                description: "",
                externalLink: "",
                imagePath: "",
                registeredVolunteers: ""
            }}

            onSubmit = {async (values, { setSubmitting }) => { 
                console.log("Submitting"); 
                setSubmitting(true);
                setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                }, 400);

                try {
                    app.database()
                        .ref().child('volunteerActivity').push({
                            name: values.name,
                            date: values.date,
                            startTime: values.startTime,
                            endTime: values.endTime,
                            location: values.location,
                            hostOrganization: values.hostOrganization,
                            suitableParticipants: values.suitableParticipants,
                            socialImpactArea: values.socialImpactArea,
                            shortDesc: values.shortDesc,
                            description: values.description,
                            externalLink: values.externalLink,
                            imagePath: "search-sample-1.png",
                        });
                } catch (error) {
                    alert(error);
                }
                
                window.location = '/search'
            }}       

            validationSchema = {createActivitySchema}
        >
			{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
			}) => (
                <div id="create-container">
                    <div>
                        {console.log(values)}
                        <h1 className="center-text">Create New Activity</h1>                
                        <form onSubmit={handleSubmit} className="create_activity_form">
                            <label htmlFor="name">Title
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    maxLength="50"
                                    placeholder="Enter title of volunteer activity"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.name && touched.name && "highlight_error"}
                                />
                            </label>
                            {errors.name && touched.name && 
                                (<div className="validation_feedback">{errors.name}</div>)}
                            
                            <label htmlFor="date">Date of Activity
                                <input
                                    id="date"
                                    name="date"
                                    type="date"
                                    placeholder="Enter date of volunteer activity"
                                    value={values.date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.date && touched.date && "highlight_error"}
                                />
                            </label>
                            {errors.date && touched.date && 
                                (<div className="validation_feedback">{errors.date}</div>)}

                            <label htmlFor="startTime">Start Time
                                <input
                                    id="startTime"
                                    name="startTime"
                                    type="time"
                                    placeholder="Enter start time"
                                    value={values.startTime}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.startTime && touched.startTime && "highlight_error"}
                                />
                            </label>
                            {errors.startTime && touched.startTime && 
                                (<div className="validation_feedback">{errors.startTime}</div>)}

                            <label htmlFor="endTime">End Time
                                <input
                                    id="endTime"
                                    name="endTime"
                                    type="time"
                                    placeholder="Enter end time"
                                    value={values.endTime}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.endTime && touched.endTime && "highlight_error"}
                                />
                            </label>
                            {errors.endTime && touched.endTime && 
                                (<div className="validation_feedback">{errors.endTime}</div>)}

                            <label htmlFor="location">Location
                                <input
                                    id="location"
                                    name="location"
                                    type="text"
                                    maxLength="100"
                                    placeholder="Enter location of volunteer activity"
                                    value={values.location}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.location && touched.location && "highlight_error"}
                                />
                            </label>
                            {errors.location && touched.location && 
                                (<div className="validation_feedback">{errors.location}</div>)}

                            <label htmlFor="hostOrganization">Host Organization
                                <input
                                    id="hostOrganization"
                                    name="hostOrganization"
                                    type="text"
                                    maxLength="50"
                                    placeholder="Enter name of host organization"
                                    value={values.hostOrganization}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.hostOrganization && 
                                        touched.hostOrganization && "highlight_error"}
                                />
                            </label>
                            {errors.hostOrganization && touched.hostOrganization && 
                                (<div className="validation_feedback">{errors.hostOrganization}</div>)}
                            
                            <label htmlFor="suitableParticipants">Suitable Participants
                                <select 
                                    id="suitableParticipants"
                                    name="suitableParticipants" 
                                    type="text"
                                    value={values.suitableParticipants}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.suitableParticipants && 
                                        touched.suitableParticipants && "highlight_error"}>                                    
                                            <option value="Children (6-12)">Children (6-12)</option>
                                            <option value="Youth (13-17)">Youth (13-17)</option>
                                            <option value="Adults (18+)">Adults (18+)</option>
                                            <option value="Seniors (60+)">Seniors (60+)</option>
                                            <option value="All">All Ages</option>
                                            <option value="" disabled defaultValue>
                                                Select suitable participants for the activity</option>
                                </select>
                            </label>
                            {errors.suitableParticipants && touched.suitableParticipants && 
                                (<div className="validation_feedback">{errors.suitableParticipants}</div>)}

                            <label htmlFor="socialImpactArea">
                                <span>Social Impact Area
                                    <span id="sia_info"><br></br>
                                        <Link to="/about" target="_blank">
                                            <i className="fa fa-info-circle" id="info_icon"></i> What is this?
                                        </Link>
                                    </span>
                                </span>
                                <select 
                                    id="socialImpactArea"
                                    name="socialImpactArea" 
                                    type="text"
                                    value={values.socialImpactArea}
                                    onChange={handleChange}
                                    onBlur={handleBlur}   
                                    className={errors.socialImpactArea && 
                                        touched.socialImpactArea && "highlight_error"}>   
                                            <option value="Further Education and Lifelong Learning">
                                                Further Education and Lifelong Learning</option> 
                                            <option value="Mitigate Climate Change">
                                                Mitigate Climate Change</option>
                                            <option value="Help Disadvantaged or Poor">
                                                Help Disadvantaged or Poor</option>
                                            <option value="Clean Up and Renew Environment">
                                                Clean Up and Renew Environment</option>
                                            <option value="Health and Wellness">
                                                Health and Wellness</option>
                                            <option value="Newcomers or Displaced Persons">
                                                Newcomers or Displaced Persons</option>
                                            <option value="Social Assistance">
                                                Social Assistance</option>
                                            <option value="Equality, Diversity and Inclusion">
                                                Equality, Diversity and Inclusion</option>
                                            <option value="" disabled defaultValue>
                                                Select related social impact area for the activity</option>
                                </select>
                            </label>
                            {errors.socialImpactArea && touched.socialImpactArea && 
                                (<div className="validation_feedback">{errors.socialImpactArea}</div>)}

                            <label htmlFor="shortDesc">Short Description
                                <textarea 
                                    id="shortDesc"
                                    name="shortDesc" 
                                    type="text"
                                    rows="5" 
                                    maxLength="300"
                                    placeholder="Enter a short description to describe the volunteer
                                        activity (max 500 characters)" 
                                    value={values.shortDesc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.shortDesc && touched.shortDesc && "highlight_error"}>
                                </textarea>
                            </label>
                            {errors.shortDesc && touched.shortDesc && 
                                (<div className="validation_feedback" id="short_desc_vf">
                                    {errors.shortDesc}</div>)}

                            <label htmlFor="description">Full Description
                                <textarea 
                                    id="description"
                                    name="description" 
                                    type="text"
                                    rows="10"
                                    maxLength="3500" 
                                    placeholder="Enter a full description for the volunteer activity (max 3500 characters)" 
                                    value={values.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.description && 
                                        touched.description && "highlight_error"}>
                                </textarea>
                            </label>
                            {errors.description && touched.description && 
                                (<div className="validation_feedback" id="full_desc_vf">
                                    {errors.description}</div>)}

                            <label htmlFor="externalLink">External Link
                                <input
                                    id="externalLink"
                                    name="externalLink"
                                    type="url"
                                    placeholder="Enter external link"
                                    value={values.externalLink}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.externalLink && 
                                        touched.externalLink && "highlight_error"}
                                />
                            </label>
                            {errors.externalLink && touched.externalLink && 
                                (<div className="validation_feedback" id="link_vf">
                                    {errors.externalLink}</div>)}

                            <label htmlFor="imagePath">
                                <span>Header Image<span id="img_info"><br></br>
                                    Appears at the top of activity posting
                                </span></span>
                                <input
                                    id="uploadContainer"
                                    name="imagePath"
                                    type="file"
                                    accept="image/*"
                                    value={values.imagePath}
                                    //onChange={handleChanges}
                                    onChange={handleChange}
                                    //onClick={handleUpload}
                                    onBlur={handleBlur}
                                />
                                {/* <button onClick={handleUpload}>Upload Image</button> */}
                            </label>                          
                            <button id="create_btn" type="submit" disabled={isSubmitting}>Create</button>
                        </form>
                    </div>
                </div>
            )}
        </Formik>
    );
};

class CreateNew extends React.Component {
    render() {
        return <CreateActivityView/>;
    }
}

export default withRouter(CreateNew)