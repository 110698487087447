import app from 'firebase/app';
import 'firebase/storage'
import 'firebase/auth';
import 'firebase/database';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
    constructor() {
        this.app = app.initializeApp(config);
        this.auth = this.app.auth();
        this.db = this.app.database();
    }

    // Retrieve all activities with key
    GetVolunteerActivities = async () => {

        let i;
        let searchResults = [];
        let keyResults = [];
        let ref = await this.db.ref('volunteerActivity').orderByChild('location').once('value');

        ref.forEach(childSnapshot => {
            searchResults.push(childSnapshot.val());

            // Insert key into each object
            keyResults.push(childSnapshot.key);
            for (i = 0; i < searchResults.length; i++) {
                searchResults[i]['key'] = keyResults[i];
            }
        });

        return searchResults;
    }

    // Retrieve a specific activity
    GetSpecificActivity = async (key) => {

        let activityDetails = [];
        let refByKey = await this.db.ref('volunteerActivity').orderByKey().equalTo(key).once('value');

        refByKey.forEach(childSnapshot => {
            activityDetails.push(childSnapshot.val());
        });

        return activityDetails[0];
    }

    // Retrieve number of active registered volunteers
    GetActiveVolsNumber = async (key) => {

        let i;
        let activeVols = [];
        let ref = await this.db.ref('volunteerActivity')
            .child(key + '/registeredVolunteers')
            .once('value');
        let count = 0;

        ref.forEach(childSnapshot => {
            activeVols.push(childSnapshot.val());
        })

        for (i = 0; i < activeVols.length; i++) {
            if (activeVols[i].status === "active") {
                count += 1
            }
        }

        return count;
    }

    // Retrieve all SIAs
    GetSocialImpactAreas = async () => {

        let socialImpactAreas = [];
        let ref = await this.db.ref('socialImpactArea').orderByChild('id').once('value');

        ref.forEach(childSnapshot => {
            socialImpactAreas.push(childSnapshot.val());
        });

        return socialImpactAreas;
    }
}

export default Firebase;
