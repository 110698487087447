import React from "react";
import { withRouter } from 'react-router-dom';
import '../assets/styles/main.css';
import { FirebaseContext } from '../firebase';
import Modal from 'react-awesome-modal';
import app from 'firebase/app';

class ActivityDetail extends React.Component {
  static contextType = FirebaseContext

  constructor(props) {
    super(props);
    this.state = {
      activityDetails: [],
      activeVolsNum: 0,
      key: props.match.params.key,
      visible: false,
      registered: false
    };
  }

  componentDidMount = async () => {
    return this.data();
  }

  data = async () => {
    let activityDetails = await this.context.GetSpecificActivity(this.state.key);
    let activeVolsNum = await this.context.GetActiveVolsNumber(this.state.key);
    this.setState({ activityDetails: activityDetails, activeVolsNum: activeVolsNum });
  }

  openModal = () => {
    this.setState({ visible: true });
  }

  closeModal = () => {
    this.setState({ visible: false });
  }

  registerActivity = () => {
    this.setState({ registered: true });
    this.setState({ visible: false });

    let user = app.auth().currentUser;
    if (user) {
      let uid = user.uid;
      app.database().ref()
      .child('volunteerActivity')
      .child(this.state.key)
      .child('registeredVolunteers')
      .child(uid)
      .update({ status: "inactive" });
      window.location.reload();
    } else {
      this.props.history.push("/login");
    }
  }

  render() {
    
    let { activityDetails, registered, activeVolsNum } = this.state;

    return (
      <div id="activity-detail-container">
            <Modal 
              visible={this.state.visible} 
              width="600" 
              height="300" 
              effect="fadeInUp" 
              onClickAway={() => this.closeModal()}
            >
              <div className="modal">
                  <h3>Confirm Registration</h3>
                  <div>By confirming your registration, your email will be 
                    shared with {activityDetails.hostOrganization}.</div>
                  <div className="row modal-btn">
                    <div 
                      className="confirm-btn"
                      onClick={() => this.registerActivity()}
                    >
                      Confirm
                    </div>
                    <div 
                      className="cancel-btn"
                      onClick={() => this.closeModal()}
                    >
                      Cancel
                    </div>
                  </div>
              </div>
            </Modal>
            <div className="activity-detail-header">
              <img 
                src={
                  activityDetails.imagePath == null ? "" : 
                  require("../assets/images/" + activityDetails.imagePath)
                }
                alt={activityDetails.imagePath}
                className="activity-detail-header-img"
               />
            </div>
            <div className="activity-detail-right">
              <div className="activity-detail-item">
                <div className="activity-subhead">{activityDetails.name}</div>
                <div>
                  {activityDetails.registeredVolunteers == null ? 0 : activeVolsNum}
                  &nbsp;Volunteers Registered!
                </div>
              </div>
              <button 
                id="register"
                className={registered === false ? "btnRegister" : "btnRegistered"}
                onClick={activityDetails.externalLink === "" ? 
                  () => this.openModal() : () => window.open(activityDetails.externalLink)}
              >
                {registered === false ? "Register Now" : "Thanks for registering!"}
              </button>
              <div className="activity-detail-item">
                <div className="activity-subhead">Social Impact Area</div>
                <div>{activityDetails.socialImpactArea}</div>
              </div>
              <div className="activity-detail-item">
                <div className="activity-subhead">Host Organization</div>
                <div>{activityDetails.hostOrganization}</div>
              </div>
              <div className="activity-detail-item">
                <div className="activity-subhead">Date and Time</div>
                <div>{activityDetails.date} <br />
                {activityDetails.startTime} to {activityDetails.endTime}</div>
                <div>
                  <a href="/addToCalendar">Add to Calendar</a>
                </div>
              </div>
              <div className="activity-detail-item">
                <div className="activity-subhead">Location</div>
                <div>{activityDetails.location}</div>
                <div>
                  <a href="/viewMap">View Map</a>
                </div>
              </div>
              <div className="activity-detail-item">
                <div className="activity-subhead">Suitable Participants</div>
                <div>{activityDetails.suitableParticipants}</div>
              </div>
            </div>
            <div className="activity-detail-left">
              <div className="activity-detail-item">
                <div className="activity-name">{activityDetails.name}</div>
                <div>{activityDetails.description}</div>
              </div>
            </div>
      </div>
    );
  }
}

export default withRouter(ActivityDetail);
