import React from 'react';
import propTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router'
import { FirebaseAuthProvider, FirebaseAuthConsumer } from "@react-firebase/auth";
import * as firebase from "firebase/app";
import config from "firebase/firebase";
import '../assets/styles/main.css';

class Navbar extends React.Component {

    static propTypes = {
        location: propTypes.object.isRequired
    }

    state = {
        theme: ""
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (location.pathname !== prevProps.location.pathname) {
            if (location.pathname === "/") {
                window.addEventListener("onload", this.setState({ theme: "transparent-nav" }));
            } else {
                window.addEventListener("onload", this.setState({ theme: "dark-nav" }));
            }
        }
    }

    componentDidMount() {
        const { location } = this.props;
        window.addEventListener("scroll", this.scrollToShowNav);
        if (window.pageYOffset < 300 && location.pathname === "/") {
            window.addEventListener("onload", this.setState({ theme: "transparent-nav" }));
        } else {
            window.addEventListener("onload", this.setState({ theme: "dark-nav" }));
        }
    }

    scrollToShowNav = () => {
        const { location } = this.props;
        if (window.pageYOffset < 300 && location.pathname === "/") {
            this.setState({ theme: "transparent-nav" });
        } else {
            this.setState({ theme: "dark-nav" });
        }
    }

    signOut = () => {
        
    }

    render() {
        return(
            <div id="navbar-container" className={this.state.theme}>
                <div className="navbar-logo">
                    <img src={require('../assets/images/logo.png')} alt="everserv-logo" width="90" />
                </div>
                <div className="navbar-content">
                    <div><Link to="/">Home</Link></div>
                    <div><Link to="/about">About</Link></div>
                    <div><Link to="/activities">My Activities</Link></div>
                    <div><Link to="/search">Search for Activities</Link></div>
                    <div>
						<FirebaseAuthProvider {...config} firebase={firebase}>
							<FirebaseAuthConsumer>
							{({ isSignedIn }) => {
								if (isSignedIn === true) {
									return <Link to="/login" 
                                    onClick={() => { 
                                        firebase.auth().signOut() && 
                                        localStorage.clear();
                                    }}>
                                    Logout</Link>;
								}
								else {
									return <Link to="/login">Login</Link>;
								}
							}}
							</FirebaseAuthConsumer>
                        </FirebaseAuthProvider>
                    </div>
                    {localStorage.getItem("email") == null ? null : 
                    <div className="menu-email">{localStorage.getItem("email")}</div>}
                </div>
            </div>
        )
    }
}

export default withRouter(Navbar);