import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import '../assets/styles/main.css';
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import { Formik } from "formik";
import * as Yup from "yup";

class Login extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			loginError: false
		}
	}

	handleLogin = async event => {
		event.preventDefault();
		const { email, password } = event.target.elements;
		try {
			await firebase.auth()
				.signInWithEmailAndPassword(email.value, password.value)
				.then(user => {
					localStorage.setItem("user", user);
					localStorage.setItem("email", user.user.email);
				});
			this.props.history.push("/search");
		} catch (error) {
			this.setState({ loginError: true });
		}
	};

	render() {
		return (
			<div className="auth-container">
			<h1 className="center">Log In</h1>
			<Formik 
				initialValues={{ email: '', password: ''}}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						alert(JSON.stringify(values, null, 2));
						setSubmitting(false);
					}, 400);}}
				validationSchema = {LoginSchema}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					isSubmitting,
				}) => (
					<form onSubmit={this.handleLogin} className="auth-form">
						{ this.state.loginError ? <LoginError /> : "" }
						<label htmlFor="email">Email</label>
						<input
							id="email"
							name="email"
							type="email"
							placeholder="Email"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.email}
							className={errors.email && 
								touched.email && "highlight_error"}
						/>
						<div className="auth-validation">
							{errors.email && touched.email && errors.email}
						</div>
						<label htmlFor="password">Password</label>
						<input
							id="password"
							name="password"
							type="password"
							placeholder="Password"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.password}
						/>
						<div className="auth-validation">
							{errors.password && touched.password && errors.password}
						</div>
						<button type="submit" className="auth-btn" disabled={isSubmitting} >Log In</button>
						<SignUpLink />
					</form>
				)}
			</Formik>
		</div>
		)
	}
}

const SignUpLink = () => (
	<div className="other-auth">
		Don't have an account? <Link to="/signup" className="other-auth-link">Sign Up!</Link>
	</div>
);

const LoginError = () => (
	<div className="login-error">
		<i className="fas fa-exclamation-circle fa-lg" /> Invalid email and/or password.
	</div>
);

const LoginSchema = Yup.object().shape({
	email: Yup
		.string()
		.email("Invalid email")
		.required("Email required"),
	password: Yup
		.string()
		.required("Password required")
});


export default withRouter(Login);