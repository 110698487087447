import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import '../assets/styles/main.css';
import { FirebaseContext } from '../firebase';

class Home extends React.Component {
    static contextType = FirebaseContext
  
    constructor(props) {
      super(props);
      this.state = {
        featuredActivities: [],
        socialImpactAreas: []
      };
    }
  
    componentDidMount = async () => {
      return this.updateContent();
    }
  
    updateContent = async () => {
      //Retrieve featured activities from Firebase
      let featuredActivities = await this.context.GetVolunteerActivities();
      this.setState({ featuredActivities });
      //Retrieve SIAs from Firebase
      let socialImpactAreas = await this.context.GetSocialImpactAreas();
      this.setState({ socialImpactAreas });
    }

    render() {
        const activity = this.state.featuredActivities.map((i, index) =>
        <Link
          to={{ 
            pathname: "/detail/" + this.state.featuredActivities[index]['key'], 
            detail:this.state.featuredActivities[index]
          }}
          key={index}
        >
          <div className="row" >
            <img 
                src={
                i.imagePath === "" ? "" : 
                require("../assets/images/" + i.imagePath)
                }
                className="home-sample-img"
                alt={i.name}
            />
            <div className="column">
              <h2>{i.name}</h2>
              <div className="row home-sample-details">
                <div style={{ marginRight: "10px" }}><i className="far fa-calendar"></i> {i.date}</div>
                <div style={{ marginRight: "10px" }}><i className="fas fa-map-marker-alt"></i> {i.location}</div>
                <div><i className="fas fa-building"></i> {i.hostOrganization}</div>
              </div>
              <div><h4>{i.shortDesc}</h4></div>
            </div>
          </div>
        </Link>
      );

      const socialImpactAreas = this.state.socialImpactAreas.map((i, index) =>
      <div key={index} className="impact-area center center-text">
        {/* <img src={
              i.imagePath === "" ? "" : 
              require("../assets/images/" + i.imagePath)
            } /> */}
        <div className="impact-area-desc">
          <h3>{i.name}</h3>
          <h6>{i.description} <b>Supports SDG goal {i.supportGoal}</b></h6>
        </div>
      </div>
      );
    
        return(
            <div id="home-container">
                <div className="home-image center center-text">
                    <h1 className="white-text">Welcome to EverServ!</h1>
                    <h5 className="white-text desc">Now’s the time to make a meaningful impact and give time to a community cause that matters to you.</h5>
                    <h6 className="scroll-down">Get Started</h6>
                </div>
                <div className="impact-areas center" id="impact-areas">
                    {socialImpactAreas}
                </div>
                <div className="home-sdg-goals center center-text">
                  <div>
                    EverServ platform is an <u>online marketplace</u> that makes it easier for people to search, signup and measure the impact of their volunteering activities. Our platform enables more engagement by employees of corporations and non-profit organizations to search, signup and measure the impact of their volunteering activities. The cumulative contributions of time and output made by their employees are collected into a report for the organization about their progress made toward annual <b>Environmental, Social and Governance (ESG) goals</b>. Fulfilling their planned ESG goals is good for business <u>and</u> the community. By providing a measurable system to measure how it manages corporate social responsibility (CSR), EverServ helps organizations achieve goals that deliver the <b>quadruple bottom line: benefits to people, purpose, planet and profitability</b>.  	
                  </div>
                  <h3>
                    EverServ’s mission is to create the value in volunteering and empowering employees and organizations with a valuable purpose to doing community service, in a manner that is aligned with the United Nation’s Sustainable Development Goals (SDGs).
                  </h3>
                  <br/>
                  <h2>About the United Nation’s SDGs:  17 Goals to Transform Our World</h2>
                  <div className="row">
                    <div>
                      The <u>Sustainable Development Goals</u> (SDGs) were set in 2015 by the United Nations as a global call to action by all countries to promote prosperity while protecting the planet. They recognize that ending poverty must go hand-in-hand with strategies that build economic growth and address a range of social needs. There are 17 SDGs that serve as the blueprint to achieve a better and more sustainable future for all by the year 2030. Most communities in our world face each of the 17 challenges on a daily basis to some degree, such as poverty, inequality, climate change, environmental degradation, peace and justice. EverServ platform was designed to provide a quantifiable mechanism to search, register and track the volunteering activities that align with the SDG goals. <b>Our vision for EverServ is that each member will know how much their volunteering has impacted the community and the planet, and it is our vision that this ledger can become a form of social benefit currency that circulates in our community.</b>
                    </div>
                    <img src={require("../assets/images/sdg-goals.png")} 
                      className="home-sdg"
                      alt="United Nations' Sustainable Development Goals (SDGs)"/>
                  </div>
                  
                </div>
                <div className="home-featured row center">
                    <h1>Featured Activities</h1>
                </div>
                <div className="home-sample row center">
                    {activity.slice(0,1)}
                </div>
                <div className="home-sample row center">
                    {activity.slice(4,5)}
                </div>
            </div>
        );
    }
}

export default withRouter(Home);