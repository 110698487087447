import React from "react";
import { withRouter, Link } from 'react-router-dom';
import { FirebaseContext } from '../firebase';
import '../assets/styles/main.css';

class Search extends React.Component {
  static contextType = FirebaseContext

  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      searchString: "",
      selectedSIA: "All SIA",
      selectOpen: false
    };
  }

  componentDidMount = async () => {
    return this.search();
  }

  search = async () => {
    let searchResults = await this.context.GetVolunteerActivities();
    this.setState({ searchResults });
  }

  handleOption = value => {

    this.setState({ selectOpen: !this.state.selectOpen });

    let socialImpactArea = value;
    
    if (socialImpactArea === "all") {
      this.setState({ selectedSIA: "All SIA" });
    } else if (socialImpactArea === "environment") {
      this.setState({ selectedSIA: "Clean Up and Renew Environment" });
    } else if (socialImpactArea === "community") {
      this.setState({ selectedSIA: "Community or Education" });
    } else if (socialImpactArea === "newcomers") {
      this.setState({ selectedSIA: "Newcomers or Displaced Persons" });
    } else if (socialImpactArea === "health") {
      this.setState({ selectedSIA: "Health and Wellness" });
    } else if (socialImpactArea === "assistance") {
      this.setState({ selectedSIA: "Social Assistance" });
    } else if (socialImpactArea === "poor") {
      this.setState({ selectedSIA: "Help Disadvantaged or Poor" });
    } else if (socialImpactArea === "equality") {
      this.setState({ selectedSIA: "Equality, Diversity and Inclusion" });
    } else if (socialImpactArea === "climate") {
      this.setState({ selectedSIA: "Mitigate Climate Change" });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ searchString: e.target[0].value });
  }

  render() {

    let { selectedSIA, selectOpen } = this.state;
    let searchString = this.state.searchString.trim().toLowerCase();
    let searchResults = this.state.searchResults.filter(function(i) {
      if (selectedSIA === "All SIA") {
        return i.location.toLowerCase().match(searchString);
      } else {
        return i.location.toLowerCase().match(searchString) 
        && i.socialImpactArea.includes(selectedSIA);
      }
    })

    return (
      <div id="search-container">
        <div className="center-text">
          <h1>Search for Activities</h1>
        </div>
        <div className="center-text">
          <form 
            onSubmit={(e) => this.handleSubmit(e)}
            className="row center"
            style={{ alignItems: "baseline" }}
          >
            <input 
              type="text" 
              className="search-input" 
              placeholder="Search Location"
            />
            <div className="options-wrapper">
              {optionDetails.map((i, index) =>
                selectOpen === false ?
                (selectedSIA === i.name ? 
                  (<div
                    key={index}
                    className="selected-option"
                    onClick={() => this.handleOption(i.value)}
                  >
                    {i.name}
                    <i className="fas fa-angle-down fa-lg"></i>
                  </div>) : null
                ) : 
                (<div
                  key={index}
                  className="search-options"
                  onClick={() => this.handleOption(i.value)}
                 >
                  {i.name}
                 </div>)
              )}
            </div>
            <input type="submit" className="search-button" value="Find" />
          </form>
          { 
            localStorage.getItem("user") == null ? null : 
            <Link to="/createNew"><h4 className="create-new-btn blue-text">or Create New Activity</h4></Link>
          }
        </div>
        <div className="search-results">
          { searchString.length === 0 ? 
            <div style={{ marginBottom: 30 }}>
              {searchResults.length} Results for <strong>All Locations</strong>
            </div> :
            <div style={{ marginBottom: 30 }}>
              {searchResults.length} Results for <strong>{searchString}</strong>
            </div> 
          }
          {searchResults.map((i, index) =>
            <Link 
              to={{ 
                pathname: "/detail/" + searchResults[index]['key'], 
                detail: searchResults[index]
              }}
              key={index}
            >
              <div className="row activity">
                <img 
                  src={
                    i.imagePath === "" ? "" : 
                    require("../assets/images/" + i.imagePath)
                  }
                  alt="search-sample-img"
                  className="search-sample-img"
                />
                <div className="column">
                  <h3>{i.name}</h3>
                  <div className="column result-details">
                    <div style={{ marginRight: "10px" }}><i className="far fa-calendar"></i> {i.date}</div>
                    <div style={{ marginRight: "10px" }}><i className="fas fa-map-marker-alt"></i> {i.location}</div>
                    <div><i className="fas fa-building"></i> {i.hostOrganization}</div>
                  </div>
                  <div>{i.shortDesc}</div>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    )
  }
}

let optionDetails = [
  {
    id: 1,
    value: "all",
    name: "All SIA"
  },
  {
    id: 2,
    value: "environment",
    name: "Clean Up and Renew Environment"
  },
  {
    id: 3,
    value: "climate",
    name: "Mitigate Climate Change"
  },
  {
    id: 4,
    value: "newcomers",
    name: "Newcomers or Displaced Persons"
  },
  {
    id: 5,
    value: "health",
    name: "Health and Wellness"
  },
  {
    id: 6,
    value: "assistance",
    name: "Social Assistance"
  },
  {
    id: 7,
    value: "poor",
    name: "Help Disadvantaged or Poor"
  },
  {
    id: 8,
    value: "equality",
    name: "Equality, Diversity and Inclusion"
  }
]

export default withRouter(Search);