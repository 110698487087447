import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import Navbar from '../src/components/Navbar';
import Home from '../src/pages/Home';
import About from '../src/pages/About';
import Search from '../src/pages/Search';
import CreateNew from '../src/pages/CreateNew';
import ActivityDetail from "../src/pages/ActivityDetail";
import { Footer } from '../src/components/Footer';
import Activities from './pages/Activities';
import Login from './pages/Login';
import SignUp from './pages/Signup';
import ScrollToTop from './components/ScrollToTop';

class App extends React.Component {
  render() {

    let userStatus = localStorage.getItem("user") == null ? false : true;

    return (
      <Router>
        <ScrollToTop>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/search" component={Search} />
            <Route path="/activities" component={userStatus ? Activities : Login} />
            <Route path="/createnew" component={CreateNew} />
            <Route path="/detail/:key" component={ActivityDetail} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
