import React from 'react';
import '../assets/styles/main.css';

export class Footer extends React.Component {
    render() {
        return(
            <div id="footer-container">
                <div className="row">
                    <div className="column grey-text">
                        <h4 className="white-text">Features</h4>
                        <div>About</div>
                        <div>My Activities</div>
                        <div>Search for Activities</div>
                        <div>Support</div>
                    </div>
                    <div className="column grey-text">
                        <h4 className="white-text">Features</h4>
                        <div>About</div>
                        <div>My Activities</div>
                        <div>Search for Activities</div>
                        <div>Support</div>
                    </div>
                    <div className="footer-desc white-text">
                        <h5 className="grey-text">Duis efficitur vel lacus in interdum.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent vitae lacinia neque.</h5>
                        <div className="row">
                            <i className="fab fa-linkedin-in"></i>
                            <i className="fab fa-facebook-f"></i>
                            <i className="fab fa-twitter"></i>
                            <i className="fab fa-google-plus-g"></i>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="line center"></div>
                    <div className="copyright grey-text center-text">Optimal Innovation Group © 2020</div>
                </div>
            </div>
        )
    }
}