import React from 'react';
import { withRouter } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import '../assets/styles/main.css';
import { FirebaseContext } from '../firebase';

class About extends React.Component {
  static contextType = FirebaseContext

  constructor(props) {
    super(props);
    this.state = {
      featuredActivities: [],
      socialImpactAreas: []
    };
  }

  componentDidMount = async () => {
    return this.updateContent();
  }

  updateContent = async () => {
    //Retrieve SIAs from Firebase
    let socialImpactAreas = await this.context.GetSocialImpactAreas();
    this.setState({ socialImpactAreas });
  }

  render() {
    const socialImpactAreas = this.state.socialImpactAreas.map((i, index) =>
      <div key={index}>
        <Collapsible trigger={i.name}>
          <h3>{i.description}</h3>
          <b>Supports SDG goal {i.supportGoal}</b>
          <img src={require("../assets/images/" + i.imagePath)} alt={i.name} />
        </Collapsible>
      </div>
    );
    return(
      <div id="about-container">
        <div className="center center-text"> 
          <h1>About EverServ</h1>
          <p className="about-desc">EverServ platform is an <u>online marketplace</u> that makes it easier for people to search, signup and measure the impact of their volunteering activities. Our platform enables more engagement by employees of corporations and non-profit organizations to search, signup and measure the impact of their volunteering activities. The cumulative contributions of time and output made by their employees are collected into a report for the organization about their progress made toward annual <b>Environmental, Social and Governance (ESG) goals</b>. Fulfilling their planned ESG goals is good for business <u>and</u> the community. By providing a measurable system to measure how it manages corporate social responsibility (CSR), EverServ helps organizations achieve goals that deliver the <b>quadruple bottom line: benefits to people, purpose, planet and profitability</b>.  	</p>
        </div>
        <div className="center">
          <h2 className="center-text">Social Impact Areas</h2><br></br>
          {socialImpactAreas}
        </div>
        <div className="get-in-touch-section center-text">
          <h3 id="get-in-touch-heading">Get in Touch</h3>
          <p id="get-in-touch-text">Whether you have questions about features, need a demo, or anything else, we look forward to hearing from you. Our team is ready to answer all of your questions. </p><br/><br/>
          <div>
              <button id="contact-us-btn" type="button">Contact Us</button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(About);
