import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FirebaseContext } from '../firebase';
import '../assets/styles/main.css';

class Activities extends React.Component {
  static contextType = FirebaseContext

  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      tabIndex: 0
    };
  }

  componentDidMount = async () => {
    return this.search();
  }

  search = async () => {
    let searchResults = await this.context.GetVolunteerActivities();
    this.setState({ searchResults });
  }
  
  render() {

    let { tabIndex } = this.state;
    let searchResults = this.state.searchResults.filter(function(i) {
      if (tabIndex === 1) {
        return new Date(i.date).toLocaleDateString() > new Date().toLocaleDateString();
      } else if (tabIndex === 2) {
        return new Date(i.date).toLocaleDateString() < new Date().toLocaleDateString();
      } else {
        return i.date;
      }
    })

    const activity =
      searchResults.map((i, index) =>
        <Link
          to={{ 
            pathname: "/detail/" + this.state.searchResults[index]['key'], 
            detail:this.state.searchResults[index]
          }}
          key={index}
        >
          <div className="row" >
          <img 
            src={
              i.imagePath === "" ? "" : 
              require("../assets/images/" + i.imagePath)
            }
            className="activity-img" 
            alt={i.name}
          />
            <div className="column">
              <h3 className="activity-title">{i.name}</h3>
              <div className="column result-details">
                <div style={{ marginRight: "10px" }}><i className="far fa-calendar"></i> {i.date}</div>
                <div style={{ marginRight: "10px" }}><i className="fas fa-map-marker-alt"></i> {i.location}</div>
                <div><i className="fas fa-building"></i> {i.hostOrganization}</div>
              </div>
              <div>{i.shortDesc}</div>
            </div>
          </div>
        </Link>
      );

    return (
      <div id="my-activities-container">
        <div className="center">
          <div className="center-text"><h1>My Activities</h1></div>
          <div>
            <Tabs 
              selectedIndex={this.state.tabIndex}
              onSelect={tabIndex => this.setState({ tabIndex })} 
              className="activities-tabs"
            >
              <TabList style={{ display: "flex", justifyContent: "center" }}>
                <Tab>View All</Tab>
                <Tab>Coming Up</Tab>
                <Tab>Past Activities</Tab>
              </TabList>
              <TabPanel>
                  <div style={{ marginBottom: 30 }}>
                    {activity.length} Results for <strong>All Activities</strong>
                  </div> 
                  {activity}
              </TabPanel>
              <TabPanel>
                  <div style={{ marginBottom: 30 }}>
                    {activity.length} Results for <strong>Coming Up Activities</strong>
                  </div> 
                  {activity}
              </TabPanel>
              <TabPanel>
                  <div style={{ marginBottom: 30 }}>
                    {activity.length} Results for <strong>Past Activities</strong>
                  </div> 
                  {activity}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Activities);