import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import 'firebase/auth';
import 'firebase/database';
import app from 'firebase/app';
import { Formik } from "formik";
import * as Yup from "yup";

const LoginLink = () => (
	<div className="other-auth">
		Already have an account? <Link to="/login" className="other-auth-link">Log in!</Link>
	</div>
	);

const SignupSchema = Yup.object().shape({
	email: Yup
		.string()
		.email("Invalid email")
		.required("Email required"),
	password: Yup
		.string()
		.min(8, 'Password must be min. 8 characters long')
    .matches(/(?=.*[0-9])/, "Password must contain a number.")
		.required("Password required"),
	passwordCheck: Yup
		.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required("Password confirmation is required"),
});

const SignUpView = ({ onSubmit }) => {
    return (
      <div className="auth-container">
        <h1 className="center">Sign up</h1>
        <Formik 
					initialValues={{ email: '', password: '', passwordCheck: ''}}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							alert(JSON.stringify(values, null, 2));
							setSubmitting(false);
						}, 400);}}
					validationSchema = {SignupSchema}
				>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					isSubmitting,
				}) => (
					<form onSubmit={onSubmit} className="auth-form">
						<label for="email">Email</label>
						<input
							id="email"
							name="email"
							type="email"
							placeholder="Email"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.email}
							className={errors.email && 
								touched.email && "highlight_error"}
						/>
          	<div className="auth-validation">
							{errors.email && touched.email && errors.email}
						</div>
						<label for="password">Password</label>
						<input
							id="password"
							name="password"
							type="password"
							placeholder="Password"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.password}
						/>
          	<div className="auth-validation">
							{errors.password && touched.password && errors.password}
						</div>
						<label for="passwordCheck">Confirm Password</label>
						<input
							id="passwordCheck"
							name="passwordCheck"
							type="password"
							placeholder="Confirm your password"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.passwordCheck}
						/>
          	<div className="auth-validation">
							{errors.passwordCheck && touched.passwordCheck && errors.passwordCheck}
						</div>
						<button type="submit" disabled={isSubmitting} className="auth-btn">Sign Up</button>
						<LoginLink />
					</form>
				)}
        </Formik>
      </div>
    );
  };

class SignUp extends React.Component {
	handleSignUp = async event => {
			event.preventDefault();
			const { email, password } = event.target.elements;
			try {
				await app.auth()
					.createUserWithEmailAndPassword(email.value, password.value);
				this.props.history.push("/search");
				localStorage.setItem("user", email.value);
				localStorage.setItem("email", email.value);
			} catch (error) {
				alert(error);
			}
		};
	
		render() {
			return <SignUpView onSubmit={this.handleSignUp} />;
	}
}

export default withRouter(SignUp);